<template>
  <v-container>
    <v-card-title class="h1 justify-center">{{ action }} Região</v-card-title>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form>
        <v-row justify="center">
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="sigla"
              rules="required"
            >
              <v-text-field
                v-model="regiao.sigla"
                :error-messages="errors"
                label="Sigla"
                prepend-icon="mdi-home-city"
                required
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="estados"
              rules="required"
            >
              <v-autocomplete
                v-model="regiao.estado"
                @change="getMunicipiosDoEstadoSelecionado"
                :items="estados"
                :error-messages="errors"
                :loading="loadingEstados"
                item-text="nome"
                item-value="id"
                label="Estado"
                prepend-icon="mdi-city-variant"
                hint="Selecione o estado da região"
                persistent-hint
                required
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="municipios"
              rules="required"
            >
              <v-autocomplete
                v-model="regiao.municipio"
                :items="municipios"
                :error-messages="errors"
                :loading="loadingMunicipios"
                item-text="nome"
                item-value="id"
                label="Município"
                prepend-icon="mdi-city"
                hint="Selecione o município do estado da região"
                persistent-hint
                required
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="frete"
              rules="required"
            >
              <v-select
                v-model="regiao.tipo_frete"
                :items="tiposFrete"
                :error-messages="errors"
                label="Tipo de Frete"
                prepend-icon="mdi-truck"
                hint="Selecione o tipo de frete da região"
                chips
                multiple
                persistent-hint
                required
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <v-card-actions>
              <v-spacer />
              <buttons-cadastro
                :disabled="invalid"
                :value="action === 'Editar' ? 'Atualizar' : 'Cadastrar'"
                @save="save"
                @close="close"
              />
            </v-card-actions>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'
import UtilsApi from '@/services/utils/utils.api'

export default {
  name: 'CadastrarRegiao',

  props: {
    regiaoASerEditada: Object,
    action: {
      type: String,
      default: 'Cadastrar',
    },
  },

  components: { ButtonsCadastro },

  data: function () {
    return {
      estados: [],
      loadingEstados: true,

      municipios: [],
      loadingMunicipios: true,

      regiao: {},
      tiposFrete: ['CIF', 'FOB'],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
  },

  created: async function () {
    this.estados = await UtilsApi.getEstadosBrasil()
    this.loadingEstados = false

    if (this.regiaoASerEditada) {
      this.regiao = { ...this.regiaoASerEditada }

      if (this.regiao.estado) {
        const { municipios } = await UtilsApi.getCidadesEstado(
          this.regiao.estado.id
        )
        this.municipios = municipios
        this.loadingMunicipios = false
      }
    }
  },

  methods: {
    ...mapActions('patio', ['cadastrarRegiao']),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    getMunicipiosDoEstadoSelecionado: async function (estadoId) {
      this.loadingMunicipios = true

      const { municipios } = await UtilsApi.getCidadesEstado(estadoId)
      this.municipios = municipios

      this.loadingMunicipios = false
    },
    close: async function () {
      this.$emit('close')
    },
    save: async function () {
      if (this.regiaoASerEditada) {
        this.$emit('editar', this.regiao)
      } else {
        try {
          await this.cadastrarRegiao(this.regiao)

          this.successMessage('Região cadastrada com sucesso!')
          this.close()
        } catch (e) {
          this.errorMessage(e.response.data)
        }
      }
    },
  },
}
</script>
