<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    />
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Regiões">
          <cadastrar-regiao v-if="acao === 'cadastrar'" @close="alterarAcao" />
          <editar-regiao
            v-else-if="acao === 'editar'"
            @close="alterarAcao"
            :regiaoASerEditada="regiaoASerEditada"
          />
          <listar-regiao
            v-else-if="acao === 'listar'"
            @cadastrar="alterarAcao"
            @editar="editarRegiao"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarRegiao from '@/components/patio/Regiao/ListarRegiao'
import CadastrarRegiao from '@/components/patio/Regiao/CadastrarRegiao'
import EditarRegiao from '@/components/patio/Regiao/EditarRegiao'

export default {
  name: 'Regiões',

  components: { ListarRegiao, CadastrarRegiao, EditarRegiao },

  data: function () {
    return {
      page: {
        title: 'Gerenciamento de Regiões',
      },
      breadcrumbs: [
        {
          text: 'Logística',
          disabled: true,
        },
        {
          text: 'Cotas',
          disabled: true,
        },
        {
          text: 'Regiões',
          disabled: false,
        },
      ],
      // cadastrar, listar, editar
      acao: 'listar',
      regiaoASerEditada: {},
    }
  },
  methods: {
    alterarAcao: function (acao = 'listar') {
      this.acao = acao
    },
    editarRegiao: function (regiao) {
      this.regiaoASerEditada = regiao
      this.acao = 'editar'
    },
  },
}
</script>
