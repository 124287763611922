<template>
  <div>
    <confirm-dialog @confirm="confirmDialog" />
    <br />
    <v-data-table
      :headers="headers"
      :items="regioes"
      :sort-by="['estado.nome', 'municipio.nome', 'sigla']"
      class="border"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Regiões Cadastradas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-icon v-if="canList" title="Atualizar" medium @click="getRegioes()"
            >mdi-refresh</v-icon
          >
          <v-spacer />
          <v-btn
            v-if="canCreate"
            color="primary"
            dark
            class="mb-2"
            @click="$emit('cadastrar', 'cadastrar')"
            >Nova Região</v-btn
          >
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="canUpdate"
          title="Editar Região"
          small
          class="mr-2"
          color="black"
          @click="$emit('editar', item)"
          >mdi-square-edit-outline</v-icon
        >
        <v-icon
          v-if="canDelete"
          title="Inativar Região"
          small
          color="black"
          @click="deleteItem(item)"
          >mdi-delete</v-icon
        >
      </template>
      <template #no-data v-if="!canList">
          <span class="red--text"
            >Você não tem permissão para visualizar as informações desta
            tela</span
          >
        </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ConfirmDialog from '@/components/template/ConfirmDialog'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'ListarRegiao',

  components: { ConfirmDialog },

  data: function () {
    return {
      headers: [
        { text: 'Sigla', value: 'sigla' },
        { text: 'Município', value: 'municipio.nome' },
        { text: 'Estado', value: 'estado.nome' },
        { text: 'Tipo de Frete', value: 'tipo_frete' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      confirmDialogItem: {},
    }
  },

  computed: {
    ...mapState('patio', ['regioes']),

    permission() {
      return Sequences.Colaboradores.toString()
    },

    canList() {
      return this.$canDo(
        BasicPermissions.VISUALIZAR,
        this.permission
      )
    },
    canCreate() {
      return this.$canDo(
        BasicPermissions.CADASTRAR,
        this.permission
      )
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(
        BasicPermissions.EXCLUIR,
        this.permission
      )
    },
  },

  created: async function () {
    if (this.canList) {
      await this.getRegioes()
    }
  },

  methods: {
    ...mapActions('patio', ['getRegioes', 'desativarRegiao']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),

    deleteItem: function (item) {
      this.confirmDialogItem = item

      this.setDialogMessage({
        title: 'Inativar Região',
        message: `Deseja realmente Inativar a região ${item.sigla.toUpperCase()}?`,
      })
    },

    confirmDialog: async function () {
      try {
        await this.desativarRegiao(this.confirmDialogItem)
        this.successMessage('Região inativada com sucesso!')

        this.confirmDialogItem = {}
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>
