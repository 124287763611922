<template>
  <cadastrar-regiao
    @close="close"
    @editar="save"
    action="Editar"
    :regiaoASerEditada="regiaoASerEditada"
  />
</template>

<script>
import { mapActions } from 'vuex'
import CadastrarRegiao from '@/components/patio/Regiao/CadastrarRegiao'

export default {
  name: 'EditarRegiao',

  props: {
    regiaoASerEditada: {
      type: Object,
      required: true,
    },
  },

  components: { CadastrarRegiao },

  methods: {
    ...mapActions('patio', ['updateRegiao']),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    close: function () {
      this.$emit('close')
    },
    save: async function (regiao) {
      Object.assign(regiao, {
        estado: regiao.estado.id ? regiao.estado.id : regiao.estado,
        municipio: regiao.municipio.id ? regiao.municipio.id : regiao.municipio,
      })

      try {
        await this.updateRegiao(regiao)

        this.successMessage('Região atualizada com sucesso!')
        this.close()
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>