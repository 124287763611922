var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card-title',{staticClass:"h1 justify-center"},[_vm._v(_vm._s(_vm.action)+" Região")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"sigla","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Sigla","prepend-icon":"mdi-home-city","required":""},model:{value:(_vm.regiao.sigla),callback:function ($$v) {_vm.$set(_vm.regiao, "sigla", $$v)},expression:"regiao.sigla"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"estados","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.estados,"error-messages":errors,"loading":_vm.loadingEstados,"item-text":"nome","item-value":"id","label":"Estado","prepend-icon":"mdi-city-variant","hint":"Selecione o estado da região","persistent-hint":"","required":""},on:{"change":_vm.getMunicipiosDoEstadoSelecionado},model:{value:(_vm.regiao.estado),callback:function ($$v) {_vm.$set(_vm.regiao, "estado", $$v)},expression:"regiao.estado"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"municipios","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.municipios,"error-messages":errors,"loading":_vm.loadingMunicipios,"item-text":"nome","item-value":"id","label":"Município","prepend-icon":"mdi-city","hint":"Selecione o município do estado da região","persistent-hint":"","required":""},model:{value:(_vm.regiao.municipio),callback:function ($$v) {_vm.$set(_vm.regiao, "municipio", $$v)},expression:"regiao.municipio"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"frete","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.tiposFrete,"error-messages":errors,"label":"Tipo de Frete","prepend-icon":"mdi-truck","hint":"Selecione o tipo de frete da região","chips":"","multiple":"","persistent-hint":"","required":""},model:{value:(_vm.regiao.tipo_frete),callback:function ($$v) {_vm.$set(_vm.regiao, "tipo_frete", $$v)},expression:"regiao.tipo_frete"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-actions',[_c('v-spacer'),_c('buttons-cadastro',{attrs:{"disabled":invalid,"value":_vm.action === 'Editar' ? 'Atualizar' : 'Cadastrar'},on:{"save":_vm.save,"close":_vm.close}})],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }